import { DatoCmsModalItem } from '../lib/datoCms/types';
import { useModals } from '../context/ModalsContext';

export const useGetModalByKey = (key: string) => {
  const modals = useModals();
  const modal = modals?.find((modal) => modal.idModal === key);
  return modal || undefined;
};

export const useGetLabelModalByKey = (key: string, item?: DatoCmsModalItem) => {
  return Reflect.get(item?.labels ?? {}, key) ?? '';
};
