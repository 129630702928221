import useLongPress from 'hooks/useLongPress';
import React, { useMemo } from 'react';
import styles from './ButtonProgress.module.scss';

export type ButtonProgressProps = {
  children: React.ReactNode;
  handleActionLongPress: () => void;
  visualizationType?: 'primary' | 'secondary' | 'tertiary';
  fullWidth?: boolean;
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean | string;
  secondsAnimation?: number;
};

type MyCustomCss = {
  '--seconds-animation': string;
} & React.CSSProperties;

export const ButtonProgress = ({
  children,
  visualizationType = 'primary',
  fullWidth,
  disabled,
  size,
  secondsAnimation = 3,
  handleActionLongPress,
}: ButtonProgressProps) => {
  const { handlers, className } = useLongPress(handleActionLongPress, secondsAnimation);

  const buttonClassName = useMemo(() => {
    let classes = `${styles.button}  ${visualizationType ? styles[visualizationType] : ''} ${
      fullWidth ? styles.fullWidth : ''
    } ${size ? styles[size] : ''}
	${disabled ? styles.disabled : ''}`;
    if (className) {
      classes = `${classes} ${styles[className]}`;
    }
    return classes;
  }, [className, visualizationType, fullWidth, disabled, size]);

  return (
    <button
      className={buttonClassName}
      {...handlers}
      style={{ '--seconds-animation': `${secondsAnimation}s` } as MyCustomCss}
    >
      <span>{children}</span>
    </button>
  );
};
