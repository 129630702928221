import { Icon, IconsEnum } from 'components/Icons';
import { selectAvatar } from 'features/dashboard/selectors';
import { useTypedSelector } from 'lib/centralStore';
import Image from 'next/image';
import { useState } from 'react';
import styles from './SelectAccordion.module.scss';

export type SelectAccordionProps = {
  selectedItem?: {
    label: string;
    value: string;
  };
  items: {
    label: string;
    value: string;
  }[];
  onChange: (item: { label: string; value: string }) => void;
};

export const SelectAccordion = ({ selectedItem, items, onChange }: SelectAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const avatar = useTypedSelector(selectAvatar);

  return (
    <div className={styles.container}>
      <button className={styles.triggerAccordion} onClick={() => setIsOpen(!isOpen)}>
        {!avatar ? (
          <Icon iconId={IconsEnum['USER']} className={styles.iconUser} color="var(--color-on-bg-primary)" />
        ) : (
          <div className={styles.iconUser}>
            <Image src={avatar.src} alt="avatar" width={24} height={24} style={{ borderRadius: '50%' }} />
          </div>
        )}
        <span>{selectedItem?.label}</span>
        <Icon
          iconId={IconsEnum.CHEWRON_DOWN}
          /* id={toIconsEnum(arrow)} */
          className={`${styles.chewron} ${isOpen ? styles.arrowUp : styles.arrowDown}`}
          color="var(--color-on-bg-primary)"
        />
      </button>
      {isOpen && (
        <>
          <div className={styles.divider}></div>
          <div className={styles.content}>
            {items.map((item) => {
              return (
                <div
                  key={item.value}
                  className={`${styles.item}`}
                  onClick={() => {
                    onChange(item);
                    setIsOpen(false);
                  }}
                >
                  {item.value === selectedItem?.value ? (
                    <Icon
                      iconId={IconsEnum.SUCCESS}
                      /* id={toIconsEnum(arrow)} */
                      className={`${styles.icon}`}
                      color="#62C5DB"
                    />
                  ) : (
                    <div className={styles.icon}></div>
                  )}
                  <span className={styles.label}>{item.label}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
