import { isGermanSite } from 'utility/constant';
import { ProfileContractApiHappybet } from '../lib/api/profileContractApiHappybet';
import { ProfileContractEditRequest } from '../types/dashboard/profileContractEditRequest';

export function populateProfileContractEditRequest(
  profile: ProfileContractApiHappybet,
  csmfCod?: string
): ProfileContractEditRequest {
  return {
    csmf_cod: csmfCod, //*
    idSessione: '',
    nome: profile.nome,
    cognome: profile.cognome,
    dataNascita: profile.dataNascita,
    comuneNascita: profile.luogoNascita,
    provinciaNascita: profile.provinciaNascita ?? '',
    indirizzoResidenza: profile.indirizzoResidenza, //*
    provinciaResidenza: profile.provinciaResidenza ?? '', //*
    comuneResidenza: profile.comuneResidenza, //*
    capResidenza: profile.capResidenza, //*
    indirizzoDomicilio: profile.indirizzoInfo ?? '', //*
    provinciaDomicilio: profile.provinciaInfo ?? '', //*
    comuneDomicilio: profile.comuneInfo ?? '', //*
    capDomicilio: profile.capInfo ?? '', //*
    cf: profile.cf ?? '',
    codiceTipoDoc: Number(profile.codiceTipoDoc),
    codiceTipoAutorita: profile.autoritaRilascioDoc,
    numeroDoc: profile.numeroDoc,
    dataRilascioDoc: profile.dataRilascioDoc,
    dataScadenzaDoc: profile.dataScadenzaDoc,
    luogoRilascioDoc: profile.luogoRilascioDoc,
    sesso: Number(profile.sesso),
    email: profile.email, //*
    telefonoMobile: profile.telefonoMobile, //*
    flagMarketing: 0, //*
    flagProfilazione: 0, //*
    codicePromo: '',
    username: '', //*
    password: '',
    codiceTipoDomandaSegreta: 0, //*
    rispostaDomandaSegreta: '', //*
    limiteVersamento: 0,
    provenienza: '',
    spidAccess: true,
    codiceIobb: '',
    firstReferral: '',
    lastReferral: '',
    firstPage: '',
    idOtpEmail: '',
    idOtpTelefono: '',
    tipoinvio: 'mail', //* valori ammessi: mail/sms
    cognomeNascita: profile.cognomeNascita,
    tipoState: profile?.tipoState ? profile?.tipoState : '',
  };
}

export const isCustomerCardHappybet = (csmfcod?: string) => {
  if (!csmfcod) return false;
  return isGermanSite ? csmfcod !== '4' : csmfcod !== '3';
};
