import { MouseEvent, TouchEvent, useRef, useState } from 'react';

export default function useLongPress(action: () => void, seconds: number) {
  const [className, setClassName] = useState<string>();
  const refMs = useRef(0);

  const timerRef = useRef<ReturnType<typeof setInterval>>();

  const startPressTimer = () => {
    setClassName('progress');
    const milliseconds = seconds * 1000;
    timerRef.current = setInterval(() => {
      refMs.current = refMs.current + 1000;
      if (refMs.current === milliseconds) {
        clearInterval(timerRef.current);
        action();
      }
    }, 1000);
  };

  const handleOnMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (event.button === 0) startPressTimer();
  };

  const handleOnTouchStart = (event: TouchEvent<HTMLButtonElement>) => {
    event.preventDefault();
    startPressTimer();
  };

  const handleOnMouseUp = () => {
    setClassName(undefined);
    clearTimeout(timerRef.current);
    refMs.current = 0;
  };

  return {
    handlers: {
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnMouseUp,
      onMouseLeave: handleOnMouseUp,
    },
    className,
  };
}
